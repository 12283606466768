import * as React from 'react';
import { Typography, Card, CardContent, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { configService } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    flex: 1,
    padding: '0 8px 16px 8px',
  },
  card: {
    padding: '16px',
    '&.MuiPaper-root': {
      width: '100%',
    },
    '& .MuiTypography-root': {
      '&:first-of-type': {
        fontWeight: 600,
        marginBottom: '28px',
      },
    },
    '& .MuiCardContent-root': {
      padding: '16px 0',
      '&:last-child': {
        paddingBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '16px 0 24px 0',
      },
    },
  },
}));

export const InactiveUserCard: React.FC = () => {
  const styles = useStyles();
  const { title, message } = configService.appConfig.inactiveUserInfo;

  return (
    <Box className={styles.box}>
      <Card
        className={styles.card}
        sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
      >
        <CardContent>
          <Typography gutterBottom variant={'h4'}>
            {title}
          </Typography>

          <Typography gutterBottom variant={'h4'}>
                <div dangerouslySetInnerHTML={{__html:message }}></div>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};
