import { AxiosPromise } from 'axios';
import { makeAutoObservable } from 'mobx';
import { instance } from './instance';
import { replaceParams } from './helpers';
import { RequestConfig } from '../types';

class ApiService {
  constructor() {
    makeAutoObservable(this);
  }

  public get<T>(url: string, config?: RequestConfig): AxiosPromise<T> {
    return instance.get<T>(
      config && config.skipParamReplace ? url : replaceParams(url, config),
      config,
    );
  }

  public put<T, S>(
    url: string,
    data: T,
    config?: RequestConfig,
  ): AxiosPromise<S> {
    return instance.put(replaceParams(url, config), data, config);
  }

  public post<T, S>(
    url: string,
    data: T,
    config?: RequestConfig,
  ): AxiosPromise<S> {
    return instance.post(replaceParams(url, config), data, config);
  }
}

export const apiService = new ApiService();
