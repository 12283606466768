import { action, makeAutoObservable } from 'mobx';
import { uniqueId } from './helpers';

export enum DialogActionType {
  cancel,
  submit,
  close,
}

export interface ModalProps {
  onClose?: () => void;
  onSubmit?: (data?: unknown) => void;
}

export class Modal<T> {
  id = uniqueId();
  // @ts-ignore
  Component: React.ComponentType<T>;
  props?: Omit<T, 'onClose'>;
}

export class ModalStore {
  modalList: Array<Modal<any>> = [];
  isLoading: boolean = false;

  private _modalPromises: Array<{
    resolve: (value: any) => void;
    reject: (value: any) => void;
  }> = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  showModal<T extends ModalProps, S = any>(
    Component: React.ComponentType<T>,
    props?: Omit<T, 'onClose'>,
  ) {
    const modal = new Modal<T>();

    modal.Component = Component;
    modal.props = props;

    this.modalList.push(modal);

    return new Promise<{ operation: DialogActionType; payload: S }>(
      (resolve, reject) => this._modalPromises.push({ resolve, reject }),
    );
  }

  @action.bound
  closeModal(operation = DialogActionType.close, payload?: any) {
    const promise = this._modalPromises.pop();
    const result = { operation, payload };
    if (promise) {
      if (operation === DialogActionType.cancel) {
        promise.reject(result);
      } else if (operation === DialogActionType.submit) {
        promise.resolve(result);
      }
    } else {
      console.log('There are no promises in the list of modals');
    }

    this.modalList.pop();
  }

  @action.bound
  closeAllModal() {
    while (this.modalList.length) {
      this.closeModal();
    }
  }
}
