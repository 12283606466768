export const URLS = {
  config: '/api/configuration',
  users: 'authentication/users',
  acceptDisclaimer: 'users/accept-disclaimer',
  revokeDisclaimer: 'users/revoke-disclaimer',

  logout: 'authentication/logout',

  downloadHandBook: 'files/:identifier',
  documents: 'documents/search',
  sendNotifications: 'documents/:id',
  hierarchies: 'hierarchies/full',
  downloadFile: 'documents/:id',
};
