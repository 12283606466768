import React from 'react';
import { observer } from 'mobx-react';

import { DialogActionType } from '../../Stores/ModalStore';
import { modalStore } from '../../index';

// @ts-ignore
export const Modals: any = observer(() => {
  const onClose = () => modalStore.closeModal(DialogActionType.close);

  const onCancel = () => modalStore.closeModal(DialogActionType.cancel);

  const onSubmit = (data?: any) =>
    modalStore.closeModal(DialogActionType.submit, data);

  if (!modalStore.modalList?.length) return null;

  return modalStore.modalList.map((modal) => {
    const ModalComponent = modal.Component;

    return (
      <ModalComponent
        {...modal.props}
        onClose={onClose}
        onSubmit={onSubmit}
        onCancel={onCancel}
        key={modal.id}
      />
    );
  });
});
