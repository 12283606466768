import qs from 'qs';
import { RequestConfig } from '../types';

export function api(url: string): string {
  return `/api/${url}`;
}

export function replaceParams(url: string, config: RequestConfig = {}): string {
  return config ? replaceUrlParams(url, config.replacePathParams) : url;
}

export function replaceUrlParams(url: string, params: any = {}): string {
  return url.replace(/:(\w+)/gi, (str, bracket) => {
    if (bracket in params) {
      return params[bracket] || '';
    } else {
      console.info("url param doesn't exist");

      return '';
    }
  });
}

export function arrayParamsSerializer(requestParams: any): string {
  return qs.stringify(requestParams, { indices: false, arrayFormat: 'comma' });
}
