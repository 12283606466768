import axios from 'axios';
import {
  httpErrorHandler,
  setAuthInterceptor,
  unauthorizedInterceptor,
  updateAuthInterceptor,
} from './interceptors';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const instance = axios.create({
  baseURL: '/',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
  cancelToken: source.token,
});

instance.interceptors.request.use(setAuthInterceptor);
instance.interceptors.response.use(undefined, updateAuthInterceptor);
instance.interceptors.response.use(undefined, unauthorizedInterceptor);
instance.interceptors.response.use(undefined, httpErrorHandler);

export { instance, source };
