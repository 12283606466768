import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { FZAGPalette } from './AppTheme';

const useStyles = makeStyles((theme) => ({
  progressBarWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 999,
    backgroundColor: alpha('#fff', 0.4),
    '& .MuiCircularProgress-root': {
      color: FZAGPalette.primary.main,
    },
  },
}));

export const Spinner: React.FC = () => {
  const styles = useStyles();

  return (
    <Box className={styles.progressBarWrapper}>
      <CircularProgress size={60} thickness={2} disableShrink />
    </Box>
  );
};
