import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { debounce } from '@mui/material';
import {
  Document,
  Documents,
  DocumentsRequest,
  Hierarchy,
  HierarchyDropdownTree,
  HierarchyDTO,
  SendNotificationsRequest,
  SortModel,
} from './types';
import { api } from '../Services/ApiService/helpers';
import { URLS } from '../Services/ApiService/urls';
import { apiService } from '../Services';
import {
  DEFAULT_DESKTOP_PAGE_SIZE,
  DEFAULT_MOBILE_PAGE_SIZE,
  DEFAULT_PAGE,
  DEFAULT_THROTTLE_MS,
} from './constants';
import {
  checkIsMobileOrTablet,
  downloadFileBlob,
  renameObjectFields,
} from './helpers';

class DocumentsStore {
  public data: Document[] = [];
  public pageCount: number = 1;
  public totalItemsCount: number = 0;
  public pageSize = checkIsMobileOrTablet()
    ? DEFAULT_MOBILE_PAGE_SIZE
    : DEFAULT_DESKTOP_PAGE_SIZE;
  public page = DEFAULT_PAGE;
  public hierarchies: Hierarchy[] = [];
  public searchValue: string = '';
  public filter: string[] = [];
  public isFilterReset: boolean = false;
  public isWithSendNotificationsDocs: boolean = false;
  public loading: boolean = false;
  public sortModel: SortModel[] = [];
  private _handleSearch: () => void;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.sortModel,
      async () => {
        await this.fetchDocuments();
      },
    );
    reaction(
      () => this.page,
      async () => {
        await this.fetchDocuments();
      },
    );
    reaction(
      () => this.pageSize,
      async () => {
        await this.fetchDocuments();
      },
    );
    this._handleSearch = debounce<() => void>(
      this.fetchDocuments,
      DEFAULT_THROTTLE_MS,
    );
  }

  public get documents() {
    return [...this.data];
  }

  public get isResetButtonExist() {
    return this.searchValue || !!this.filter?.length;
  }

  public init = async () => {
    await Promise.all([this.fetchDocuments(true), this.fetchHierarchies()]);
  };

  public fetchDocuments = async (isInit: boolean = false) => {
    try {
      this.loading = true;

      const { data } = await apiService.post<DocumentsRequest, Documents>(
        api(URLS.documents),
        {
          searchText: this.searchValue,
          inHierarchies: this.filter,
          onlyWithSendNotifications: this.isWithSendNotificationsDocs,
          documentsOrder: isInit ? [] : this.sortModel,
        },

        { params: { page: this.page, itemsPerPage: this.pageSize } },
      );

      runInAction(() => {
        this.data = data.result;
        this.pageCount = data.totalPagesCount;
        this.totalItemsCount = data.totalItemsCount;
        this.loading = false;
      });
    } catch (error) {
      console.error(error);
    }
  };

  public fetchHierarchies = async () => {
    try {
      const { data } = await apiService.get<HierarchyDTO[]>(
        api(URLS.hierarchies),
        {},
      );
      this.hierarchies = renameObjectFields(data);
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  public onSetSortModel = (model: SortModel[]) => {
    this.sortModel = model;
  };

  public onCheckSendNotifications = async (
    id: string | number,
    isChecked: boolean,
  ) => {
    try {
      await apiService.put<SendNotificationsRequest, Documents>(
        api(URLS.sendNotifications),
        { sendNotifications: isChecked },
        { replacePathParams: { id } },
      );
      this.fetchDocuments();
    } catch (error) {
      console.error(error);
    }
  };

  public onSearch = (value: string) => {
    this.searchValue = value;
    this._handleSearch();
  };

  public onFilter = (
    isChecked: boolean,
    filterValue: HierarchyDropdownTree,
  ) => {
    let filterIds: string[] = [];
    const collectIds = (array: Hierarchy[]) => {
      array?.forEach?.((item) => {
        if (!item) return;
        if (!isChecked) {
          this.filter = this.filter.filter((it) => it !== item.value);
        } else {
          filterIds.push(item.value);
        }
        if (item.children) {
          collectIds(item.children);
        }
      });
    };

    const parent = filterValue;
    if (parent?.children) {
      collectIds(parent?.children);
    }
    if (!isChecked) {
      this.filter = this.filter.filter((item) => item !== filterValue.value);
    } else {
      filterIds.push(parent?.value);
      this.filter = [...this.filter, ...filterIds];
    }
  };

  public onFilterByDocsWithSendNotifications = (isChecked: boolean) => {
    this.isWithSendNotificationsDocs = isChecked;
    this.fetchDocuments();
  };

  public onHandlePageChange = (page: number) => {
    this.page = page;
  };

  public onHandlePageSizeChange = (pageSize: number) => {
    this.pageSize = pageSize;
  };

  public onHandleDownloadHandBook = async (identifier: string) => {
    await this._downloadFile(URLS.downloadHandBook, { identifier });
  };

  public onHandleDownloadDocument = async (id: string | number) => {
    await this._downloadFile(URLS.downloadFile, { id });
  };

  private _downloadFile = async (
    url: string,
    pathParams: { [key: string]: string | number },
  ) => {
    try {
      const response = await apiService.get<any>(api(url), {
        responseType: 'arraybuffer',
        replacePathParams: pathParams,
      });
      var fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .split(';')[0];
      // Filenames with a space in them are provided with surounding Double-Quotes: "my name.csv" --> This has to be removed to get the correct file-name
      if (fileName.indexOf('"') > -1) {
        fileName = fileName.replaceAll('"', '');
      }

      const fileData = response.data;

      const blob = new Blob([fileData], {
        type: 'application/octet-stream',
      });
      const blobUrl = window.URL.createObjectURL(blob);
      downloadFileBlob(fileName, blobUrl);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error(error);
    }
  };

  public resetFilter = () => {
    this.isFilterReset = true;
    this.filter = [];
    this.searchValue = '';
    this.isWithSendNotificationsDocs = false;
    this.isFilterReset = false;
    this.fetchDocuments();
  };
}
export const documentsStore = new DocumentsStore();
