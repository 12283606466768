import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from '@mui/material';

import { authService, configService } from '../../index';
import { ConfirmationModal } from './ConfirmationModal';
import { ModalProps } from '../../Stores/ModalStore';

export const PrivacyPolicyModal: React.FC<ModalProps> = observer(
  ({ ...props }) => {
    const { onAcceptPrivacyPolicy } = authService;
    const { privacyUrl } = configService.appConfig;

    const handleAccept = () => {
      if (props.onSubmit) {
        props.onSubmit();
      }
      onAcceptPrivacyPolicy();
    };

    const handleClose = () => {
      if (props.onClose) {
        props.onClose();
      }
      authService.onCancelPrivacyPolicy();
    };

    const renderContent = () => (
      <>
        <span>Bitte lesen Sie die Datenschutzbestimmungen durch.</span>
        <span>
          Wenn Sie einverstanden sind, bitten wir Sie diese zu akzeptieren.
        </span>
        <span>
          Beim Klicken auf "Akzeptieren & weiter" bestätige ich die{' '}
          <Link target={'none'} href={privacyUrl}>
            Datenschutzbestimmungen
          </Link>{' '}
          gelesen zu haben.
        </span>
      </>
    );

    return (
      <ConfirmationModal
        {...props}
        title={'Datenschutzbestimmungen'}
        content={renderContent()}
        onSubmit={handleAccept}
        submitButtonText={'Akzeptieren & weiter'}
        onClose={handleClose}
      />
    );
  },
);
