import { MOBILE_DEVICES } from './constants';
import { Hierarchy, HierarchyDTO } from './types';

export const uniqueId = () => `id_${Math.random().toFixed(10)}`;

export function downloadFileBlob(fileName: string, fileBlobURL: string) {
  const anchor = document.createElement('a');

  document.body.appendChild(anchor);

  anchor.href = fileBlobURL;
  anchor.download = fileName;

  anchor.click();
  anchor.remove();
}

export const sortBy = (arr: any[], property: string) => {
  return arr.slice(0).sort(function (a: any, b: any) {
    return a[property] > b[property] ? 1 : a[property] < b[property] ? -1 : 0;
  });
};

export const checkIsMobileOrTablet = () => {
  const devices = new RegExp(MOBILE_DEVICES, 'i');
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

  return devices.test(navigator.userAgent) || isIOS;
};

export const renameObjectFields = (arr?: HierarchyDTO[]): Hierarchy[] => {
  if (!arr?.length) {
    return [];
  }
  return arr.map((data) => {
    return {
      value: data.id,
      label: data.name,
      children: renameObjectFields(data?.childNodes),
    };
  });
};

export const findId = (id: string, arr: Hierarchy[]): Hierarchy | any => {
  return arr.reduce((a, item) => {
    if (a) {
      return a;
    }
    if (item.value === id) {
      return item;
    }
    if (item.children) {
      return findId(id, item.children);
    }
    return null;
  }, null);
};
