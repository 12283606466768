import * as React from 'react';
import { Grid, Box } from '@mui/material';
import { Contact } from './Contact';
import { Introduction } from './Introduction';
import { DataTable } from './DataTable/DataTable';
import { SearchBar } from './SearchBar';
import { authService } from '../index';
import { NoPermissionsCard } from './NoPermissionsCard';
import { InactiveUserCard } from './InactiveUserCard';

export const Layout: React.FC = () => {
  const { user } = authService;

  return (
    <>
      <Box sx={{ padding: '16px 8px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Introduction />
          </Grid>
          <Grid item xs={12} md={4}>
            <Contact />
          </Grid>
        </Grid>
      </Box>
      {user?.isInactive ? (<InactiveUserCard />) : (
        user?.hasDocumentPermissions ? (
            <Box sx={{ flexGrow: 1 }}>
              <SearchBar />
              <DataTable />
            </Box>
            ) : (
              <NoPermissionsCard />
            )
          )
       }
    </>
  );
};
