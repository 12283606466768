import React from 'react';
import { Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/Download';

import { configService } from '../../index';
import { Derivation } from '../../Stores/types';

export const getColumns = ({
  onCheck,
  onDownload,
  styles,
}: {
  onCheck: (id: number | string, isChecked: boolean) => void;
  onDownload: (id: number | string) => void;
  styles: any;
}) => {
  const { searchInfo } = configService.appConfig;
  const {
    columnHeaderDocumentNameText,
    columnHeaderLastChangesText,
    columnHeaderNotificationText,
    columnHeaderHierarchiesText,
    hideHierarchyColumn,
  } = searchInfo;

  return [
    {
      field: 'derivations',
      headerName: '',
      width: 10,
      editable: false,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div className={styles.derivations}>
          {params.value.map((item: Derivation, index: number) => {
            if (index > 4) {
              return null;
            }
            return (
              <div
                key={index}
                title={item.fileExtension}
                className={styles.fileExtension}
              >
                {item.fileExtension}
              </div>
            );
          })}
        </div>
      ),
    },
    {
      field: 'fileName',
      headerName: columnHeaderDocumentNameText,
      minWidth: 170,
      flex: 0.4,
      renderCell: (params: GridCellParams) => (
        <>
          <Typography flex={1}>{params.value}</Typography>
          <>
            {params.row.description && (
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit" className={styles.infoTitle}>
                      Beschreibung
                    </Typography>
                    <Typography className={styles.description}>
                      {params.row.description}
                    </Typography>
                  </React.Fragment>
                }
                disableFocusListener
                placement={'bottom-start'}
                classes={{ tooltip: styles.infoTooltip }}
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  className={styles.infoIcon}
                />
              </Tooltip>
            )}
            <IconButton
              title="Download"
              aria-label="download"
              onClick={() => onDownload(params.id)}
            >
              <DownloadIcon />
            </IconButton>
          </>
        </>
      ),
    },
    {
      field: 'hierarchyNames',
      headerName: columnHeaderHierarchiesText,
      minWidth: 170,
      flex: 0.4,
      editable: false,
      sortable: false,
      hide: hideHierarchyColumn,
      renderCell: (params: GridCellParams) => {
        return (
          <div>
            {params.value.map((val: string, index: number) => (
              <div key={index}>{val}</div>
            ))}
          </div>
        );
      },
    },
    {
      field: 'sendNotification',
      headerName: columnHeaderNotificationText,
      minWidth: 120,
      flex: 0.1,
      editable: false,
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={params.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onCheck(params.id, e.target.checked)
          }
        />
      ),
    },
    {
      field: 'uploaded',
      headerName: columnHeaderLastChangesText,
      type: 'date',
      minWidth: 120,
      flex: 0.1,
      editable: false,
      renderCell: (params: GridCellParams) => (
        <div>{new Date(params.value).toLocaleDateString()}</div>
      ),
    },
  ];
};
