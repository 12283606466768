import * as React from 'react';
import { observer } from 'mobx-react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { UserProfileMenu } from './UserProfileMenu/UserProfileMenu';
import { configService } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      height: '16px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: '20px',
    },
    [theme.breakpoints.up('md')]: {
      height: '27px',
    },
  },
  logoTitle: {
    display: 'none',
    '&.MuiTypography-root': {
      fontSize: '16px',
      fontWeight: 600,
      [theme.breakpoints.up('sm')]: {
        display: 'inline-block',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '18px',
      },
    },
  },
}));

export const Header: React.FC = observer(() => {
  const styles = useStyles();
  const { applicationDisplayName } = configService.appConfig;

  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <Stack direction="row" spacing={2} alignItems={'center'} flex="1 1 0%">
          <img
            className={styles.logo}
            alt="Flughafen Zürich"
            src="logo.de.svg"
          />
          <Typography component="span" classes={{ root: styles.logoTitle }}>
            |
          </Typography>
          <Typography component="span" classes={{ root: styles.logoTitle }}>
            {applicationDisplayName}
          </Typography>
        </Stack>
        <UserProfileMenu />
      </Toolbar>
    </AppBar>
  );
});
