import React, { Component } from 'react';
import { observer } from 'mobx-react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { withStyles, StyleRules, StyledComponentProps } from '@mui/styles';
import Box from '@mui/material/Box';

import { authService } from './index';
import { AppTheme, FZAGPalette } from './Components/AppTheme';
import { Spinner } from './Components/Spinner';
import { Header } from './Components/Header';
import { Layout } from './Components/Layout';
import { Footer } from './Components/Footer';
import { Modals } from './Components/Modals/Modals';
import './App.css';

const styles: StyleRules = {
  main: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: FZAGPalette.grey.dark,
  },
};

@observer
class App extends Component<StyledComponentProps> {
  async componentDidMount() {
    await authService.init();
  }

  render() {
    const { user } = authService;

    if (!user) {
      return <Spinner />;
    }

    return (
      <AppTheme>
        {user?.hasAcceptedDisclaimer && (
          <Box className={this.props.classes!.main}>
            <Header />
            <Layout />
            <Footer />
          </Box>
        )}
        <Modals />
        <ToastContainer />
      </AppTheme>
    );
  }
}

export default withStyles(styles)(App);
