import { makeAutoObservable } from 'mobx';
import { Log, User, UserManager } from 'oidc-client';

import { configService, modalStore } from '../index';
import { apiService } from './ApiService/ApiService';
import { api } from './ApiService/helpers';
import { URLS } from './ApiService/urls';
import { Config, Token, UserProfile } from './types';
import { PrivacyPolicyModal } from '../Components/Modals/PrivacyPolicyModal';

const ACCESS_TOKEN_ACCESSOR = 'access_token';

export class AuthService {
  public userManager: UserManager;
  public user: UserProfile | null = null;

  public loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.userManager = new UserManager(
      AuthService.getAuthSettings(configService.appConfig),
    );
    Log.logger = console;
    Log.level = Log.INFO;
  }

  static clearUserData = () => {
    localStorage.removeItem(ACCESS_TOKEN_ACCESSOR);
  };

  public async init() {
    try {
      this.loading = true;
      await configService.getConfiguration();
      if (!this.user) {
        await this.getUserData();
      }
      if (this.user && !this.user?.hasAcceptedDisclaimer) {
        await modalStore.showModal(PrivacyPolicyModal);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  public updateConfig() {
    this.userManager = new UserManager(
      AuthService.getAuthSettings(configService.appConfig),
    );
  }

  private static getAuthSettings(config: Config) {
    return {
      authority: config.authority,
      client_id: config.clientId,
      redirect_uri: `${config.appRoot}/signin-callback.html`,
      includeIdTokenInSilentRenew: false,
      silent_redirect_uri: `${config.appRoot}/silent-renew.html`,
      post_logout_redirect_uri: `${config.appRoot}`,
      automaticRedirectAfterSignOut: true,
      response_type: 'code',
      scope: config.clientScope,
    };
  }

  public setUser(user: UserProfile | null) {
    return (this.user = user);
  }

  public getUser(): Promise<User | null | void> {
    return this.userManager.getUser();
  }

  public getUserData(): Promise<User | null | void> {
    return this.getUser().then((user: any) => {
      if (user) {
        return this.getUserProfile({
          accessToken: user.access_token,
          idToken: user.id_token,
        });
      } else {
        console.log('You are not logged in.');
        return this.login();
      }
    });
  }

  public setToken = (currentToken: string) => {
    localStorage.setItem(ACCESS_TOKEN_ACCESSOR, currentToken);
  };

  public getAuthToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_ACCESSOR);
  };

  public login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  public getUserProfile = async (token: Token) => {
    try {
      const { data } = await apiService.post<Token | undefined, UserProfile>(
        api(URLS.users),
        token,
        {},
      );

      if (data) {
        this.setToken(data.accessToken);
        this.setUser(data);
        console.log('User has been successfully loaded from BD.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  public renewToken(): Promise<UserProfile | void | null> {
    return this.userManager
      .signinSilent()
      .then((user: any) => {
        return this.getUserProfile({
          accessToken: user.access_token,
          idToken: user.id_token,
        });
      })
      .then(() => {
        console.log('Token has been successfully renewed.');
        return this.user;
      })
      .catch((error: Error) => {
        console.error(`Error in renew token ${error}`);
        this.login();
      });
  }

  public logout = async () => {
    await apiService.put(api(URLS.logout), {}, {});

    AuthService.clearUserData();

    // this.userManager.removeUser().catch((reason) => {
    //   console.error(reason);
    // });
    // this.userManager.clearStaleState();
    
    await this.userManager.signoutRedirect().catch((reason) => {
      console.error(reason);
    });
  }

  public onAcceptPrivacyPolicy = async () => {
    try {
      await apiService.put(api(URLS.acceptDisclaimer), {}, {});
      AuthService.clearUserData();
      await this.getUserData();
    } catch (error) {
      console.error(error);
    }
  };

  public onRevokePrivacyPolicy = async () => {
    try {
      const { data } = await apiService.put<{}, { redirectToUrl: string }>(
        api(URLS.revokeDisclaimer),
        {},
        {},
      );

      await this.logout();
      setTimeout(() => (window.location.href = data?.redirectToUrl), 0);
    } catch (error) {
      console.error(error);
    }
  };

  public onCancelPrivacyPolicy = async () => {
    window.location.href =
      configService.appConfig.disclaimerCancelRedirectUrl;
  };

  public isUserHasDocumentPermissions = () => {
    return this.user?.hasDocumentPermissions;
  };
}
