import { makeAutoObservable } from 'mobx';
import { authService } from '../index';
import { URLS } from './ApiService/urls';
import { Config } from './types';

export class ConfigService {
  appConfig: Config = {
    environment: '',
    clientScope: '',
    appRoot: '',
    clientId: '',
    authority: '',
    disclaimerCancelRedirectUrl: '',
    applicationDisplayName: '',
    helpPageUrl: '',
    privacyUrl: '',
    contactInfo: {
      title: '',
      text: '',
    },
    filterInfo: {
      dialogButtonText: '',
      dialogTitleLabel: '',
      dialogApplyButtonText: '',
      dialogCancelButtonText: '',
    },
    introductionInfo: {
      title: '',
      fileDownloads: [],
      textParagraphs: [],
    },
    searchInfo: {
      searchInputText: '',
      resultsCountText: '',
      documentsPerPageText: '',
      noResultsText: '',
      columnHeaderDocumentNameText: '',
      columnHeaderHierarchiesText: '',
      columnHeaderNotificationText: '',
      columnHeaderLastChangesText: '',
      hideHierarchyColumn: false,
    },
    deactivateInfo: {
      notificationText: '',
      deactivateButtonText: '',
      cancelButtonText: '',
    },
    profileInfo: {
      profileButtonText: '',
      logoutButtonText: '',
      helpButtonText: '',
      customerPortalUrl: '',
    },
    footerInfo: {
      privacyButtonText: '',
      deactivateButtonText: '',
      companyText: '',
    },
    noDocumentPermissionsInfo: {
      title: '',
      message: '',
    },
    inactiveUserInfo: {
      title: '',
      message: '',
    },
  };
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }
  async getConfiguration() {
    try {
      this.loading = true;
      const response = await fetch(URLS.config);

      const responseData = await response.json();
      const appRoot = window.location.origin;

      this.appConfig = { ...responseData, appRoot: appRoot };

      authService.updateConfig();
      document.title = this.appConfig.applicationDisplayName;
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  }
}
