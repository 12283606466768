import * as React from 'react';
import { Box, Link, Toolbar, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FZAGPalette } from './AppTheme';
import { configService, modalStore } from '../index';
import { DeactivationWarningModal } from './Modals/DeactivationWarningModal';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    height: 'max-content',
    backgroundColor: FZAGPalette.grey.light,
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&.MuiToolbar-root': {
      height: '100%',
      padding: 0,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
  appLogo: {
    width: '140px',
    pointerEvents: 'none',
    marginRight: '32px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',
    },
  },
  imageLinkBlock: {
    display: 'flex',
    '& .MuiLink-root': {
      textAlign: 'center',
      color: FZAGPalette.text.grey,
      marginRight: '32px',
      textDecoration: 'none',
      '&:hover': {
        color: FZAGPalette.link.navy,
      },
      [theme.breakpoints.down('sm')]: {
        '&:first-of-type': {
          marginBottom: '6px',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  links: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  inactivation: {
    fontSize: '14px',
    color: FZAGPalette.text.grey,
    '&:hover': {
      cursor: 'pointer',
      color: FZAGPalette.link.navy,
    },
  },
  copyright: {
    '&.MuiTypography-root': {
      fontSize: '12px',
      color: FZAGPalette.text.lightGrey,
      [theme.breakpoints.down('md')]: {
        marginTop: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '32px',
      },
    },
  },
}));

export const Footer: React.FC = () => {
  const styles = useStyles();
  const { footerInfo } = configService.appConfig;
  const { privacyButtonText, deactivateButtonText, companyText } = footerInfo;

  const handleRevokePrivacyPolicy = async () => {
    await modalStore.showModal(DeactivationWarningModal);
  };

  return (
    <footer className={styles.footer}>
      <Toolbar className={styles.toolbar}>
        <Box className={styles.imageLinkBlock}>
          <img src="logo.de.svg" className={styles.appLogo} alt="fzag-logo" />
          <div className={styles.links}>
            <Link href={configService.appConfig.privacyUrl}>
              {privacyButtonText}
            </Link>
            <div
              className={styles.inactivation}
              onClick={handleRevokePrivacyPolicy}
            >
              {deactivateButtonText}
            </div>
          </div>
        </Box>
        <Typography variant="body1" className={styles.copyright}>
          {companyText}
        </Typography>
      </Toolbar>
    </footer>
  );
};
