import * as React from 'react';
import {
  Typography,
  Menu,
  MenuProps,
  MenuItem,
  Link,
  ListItemIcon,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { authService, configService } from '../../index';
import { FZAGPalette } from '../AppTheme';

type Setting = {
  id: number;
  title: string;
  isTitleBold?: boolean;
  icon: React.ReactNode;
  isLink?: boolean;
  link?: string;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 220,
    color: theme.palette.grey[300],
    boxShadow: FZAGPalette.boxShadow.paper,
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      height: '48px',
      '& .MuiSvgIcon-root': {
        fontSize: 24,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(3),
      },
      '& .MuiLink-root': {
        color: theme.palette.text.secondary,
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

type CustomMenuItemProps = {
  setting: Setting;
};

const CustomMenuItem: React.FC<CustomMenuItemProps> = ({ setting }) => {
  const { id, title, isLink, link, icon } = setting;

  const Title = <Typography textAlign="center">{title}</Typography>;

  return (
    <MenuItem onClick={() => (id === 2 ? authService.logout() : undefined)}>
      <a href={link} target="_blank" rel="noreferrer">
          <ListItemIcon>{icon}</ListItemIcon>
      </a>
      {isLink ? (
        <Link href={link} target="_blank" rel="noopener" underline="none">
          {Title}
        </Link>
      ) : (
        <>{Title}</>
      )}
    </MenuItem>
  );
};

type UserMenuProps = {
  anchorElUser: null | HTMLElement;
  handleCloseUserMenu: () => void;
};

export const UserMenu: React.FC<UserMenuProps> = ({
  anchorElUser,
  handleCloseUserMenu,
}) => {
  const { profileInfo, helpPageUrl } = configService.appConfig;
    const { profileButtonText, logoutButtonText, helpButtonText, customerPortalUrl } = profileInfo;

  const settings: Setting[] = [
    {
      id: 1,
      title: profileButtonText,
      isLink: true,
      link: customerPortalUrl,
      icon: <ManageAccountsOutlinedIcon fontSize="large" />,
    },
    {
      id: 2,
      title: logoutButtonText,
      icon: <LogoutIcon fontSize="large" />,
    },
    {
      id: 3,
      title: helpButtonText,
      isLink: true,
      link: helpPageUrl,
      icon: <HelpOutline fontSize="large" />,
    },
  ];
  return (
    <StyledMenu
      id="menu-appbar"
      anchorEl={anchorElUser}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      {settings.map((setting) => (
        <CustomMenuItem key={setting.id} setting={setting} />
      ))}
    </StyledMenu>
  );
};
