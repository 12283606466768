import * as React from 'react';
import { Typography, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { configService } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    height: '100%',
    '& .MuiTypography-root:first-child': {
      fontWeight: 600,
      margin: '16px 0 28px',
    },
  },
  text: {
    '&.MuiTypography-root:last-of-type': {
      marginTop: '16px',
    },
  },
}));

export const Contact: React.FC = () => {
  const styles = useStyles();
  const { contactInfo } = configService.appConfig;
  const { title, text } = contactInfo;

  return (
    <Card className={styles.card}>
      <CardContent>
        <Typography gutterBottom variant={'h4'}>
            <div dangerouslySetInnerHTML={{__html:title }}></div>
        </Typography>
        <Typography gutterBottom className={styles.text}>
            <div dangerouslySetInnerHTML={{__html:text }}></div>
        </Typography>
      </CardContent>
    </Card>
  );
};
