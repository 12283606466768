import React from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Theme,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { ModalProps } from '../../Stores/ModalStore';
import { FZAGPalette } from '../AppTheme';
import { ChaptersTree } from '../ChaptersTree';
import { documentsStore } from '../../Stores/DocumentsStore';
import { configService } from '../../index';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: '90vw',
      width: '90vw',
      height: '90vh',
      '& .MuiDialogContent-root': {
        padding: '0 14px 14px',
      },
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.MuiTypography-root': {
      fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
      color: FZAGPalette.common.black,
      fontSize: '16px',
      fontWeight: 600,
      background: FZAGPalette.grey.light,
      padding: '0 0 0 24px',
    },
  },
  btn: {
    '&.MuiButton-root': {
      fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'none',
      padding: '0 5px',
      '&:first-of-type': {
        color: FZAGPalette.text.grey,
        marginRight: '10px',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '10px',
      },
    },
  },
  content: {
    '&.MuiDialogContentText-root': {
      fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
      color: FZAGPalette.text.grey,
      fontSize: '12px',
      lineHeight: '20px',
      marginTop: '20px',
      '& span': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .MuiLink-root': {
      color: FZAGPalette.link.navy,
      textDecoration: `underline ${FZAGPalette.link.navy}`,
    },
  },
  closeButton: {
    color: FZAGPalette.grey.mountain,
    padding: 0,
    '& .MuiSvgIcon-root': {
      width: '18px',
    },
  },
  actions: {
    margin: '16px',
  },
}));

type ChaptersTreeModalProps = ModalProps & {
  content?: ReactJSXElement;
};

export const ChaptersTreeModal: React.FC<ChaptersTreeModalProps> = observer(
  ({ content, ...props }) => {
    const styles = useStyles();
    const { dialogTitleLabel, dialogApplyButtonText, dialogCancelButtonText } =
      configService.appConfig.filterInfo;

    return (
      <Dialog
        {...props}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.modal}
      >
        <DialogTitle id="alert-dialog-title" className={styles.title}>
          {dialogTitleLabel}
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={styles.content}
          >
            <ChaptersTree />
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button onClick={props.onClose} className={styles.btn}>
            {dialogCancelButtonText}
          </Button>
          <Button
            onClick={() => {
              documentsStore.fetchDocuments();
              props?.onSubmit && props?.onSubmit();
            }}
            variant="contained"
            className={styles.btn}
          >
            {dialogApplyButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
