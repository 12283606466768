import * as React from 'react';
import { observer } from 'mobx-react';
import { IconButton, Typography, Popover, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import { authService } from '../../index';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    minWidth: '130px',
    fontSize: '10px',
    color: theme.palette.common.black,
    marginTop: '3px',
  },
  companies: {
    '& .MuiPaper-root': {
      padding: '8px',
      '& *': {
        fontSize: '12px',
      },
    },
  },
  arrowIcon: {
    height: '15px',
    '&.MuiButtonBase-root': {
      padding: 0,
      '&:hover': {
        background: 'transparent',
      },
    },
  },
}));

export const CompaniesList: React.FC = observer(() => {
  const styles = useStyles();
  const [anchorElCompanies, setAnchorElCompanies] =
    React.useState<null | HTMLElement>(null);

  const id = !!anchorElCompanies ? 'company' : undefined;
  const { companies } = authService?.user!;

  const handleOpenCompanies = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCompanies(event.currentTarget);
  };

  const handleCloseCompanies = () => {
    setAnchorElCompanies(null);
  };

  return (
    <>
      <div aria-describedby={id} className={styles.title}>
        {companies?.[0]}
        {companies?.length > 1 && (
          <IconButton
            aria-label="more"
            component="span"
            className={styles.arrowIcon}
            onClick={handleOpenCompanies}
          >
            <ArrowDropDownTwoToneIcon />
          </IconButton>
        )}
      </div>
      <Popover
        id={id}
        open={!!anchorElCompanies}
        anchorEl={anchorElCompanies}
        onClose={handleCloseCompanies}
        className={styles.companies}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {companies?.map((company, index) => (
          <Typography key={index}>{company}</Typography>
        ))}
      </Popover>
    </>
  );
});
