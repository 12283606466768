import * as React from 'react';
import { Typography, Button, Card, CardContent } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { documentsStore } from '../Stores/DocumentsStore';
import { configService } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    minHeight: '179px',
    height: '100%',
    padding: '16px',
    '& .MuiTypography-root': {
      '&:first-of-type': {
        fontWeight: 600,
      },
      marginBottom: '28px',
    },
    '& .MuiCardContent-root': {
      padding: '16px 0',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 0 24px 0',
      },
    },
    '& .MuiButton-root': {
      marginTop: '14px',
      padding: '0 16px',
    },
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
    },
  },
}));

export const Introduction: React.FC = () => {
  const styles = useStyles();
  const { title, textParagraphs, fileDownloads } =
    configService.appConfig.introductionInfo;

  return (
    <Card
      className={styles.card}
      sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
    >
      <CardContent>
        <Typography gutterBottom variant={'h4'}>
            <div dangerouslySetInnerHTML={{__html:title }}></div>
        </Typography>

        <Typography gutterBottom variant={'h4'}>
          {textParagraphs?.map((paragraph: string, index: number) => (
            <React.Fragment key={index}>
                <div dangerouslySetInnerHTML={{__html:paragraph }}></div>
            </React.Fragment>
          ))}
        </Typography>

        {fileDownloads?.map((btn) => (
          <Button
            key={btn.identifier}
            variant="contained"
            onClick={() =>
              documentsStore.onHandleDownloadHandBook(btn.identifier)
            }
          >
            <PictureAsPdfIcon />
            &nbsp; {btn.displayName}
          </Button>
        ))}
      </CardContent>
    </Card>
  );
};
