import * as React from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  IconButton,
  Typography,
  Avatar,
  Tooltip,
  Stack,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { authService } from '../../index';
import { UserMenu } from './UserMenu';
import { CompaniesList } from './CompaniesList';
import { FZAGPalette } from '../AppTheme';

const useStyles = makeStyles((theme: Theme) => ({
  userTitle: {
    color: theme.palette.common.black,
    '&:first-child': {
      minWidth: '130px',
      fontSize: '16px',
    },
    '&:last-child': {
      fontSize: '10px',
      marginTop: '3px',
    },
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: '30px',
      height: '30px',
      fontSize: '14px',
    },
  },
}));

export const UserProfileMenu: React.FC = observer(() => {
  const styles = useStyles();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const { userName } = authService?.user!;

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: FZAGPalette.primary.main,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  };

  return (
    <>
      <Box ml={1.5}>
        <Tooltip title="Open settings">
          <>
            <Stack direction="row" alignItems="center">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ marginRight: '4px' }}
              >
                <Avatar
                  alt={userName}
                  {...stringAvatar(userName)}
                  className={styles.avatar}
                />
              </IconButton>
              <Stack>
                <Typography className={styles.userTitle} variant="h3">
                  {userName}
                </Typography>
                <CompaniesList />
              </Stack>
            </Stack>
          </>
        </Tooltip>
        <UserMenu
          anchorElUser={anchorElUser}
          handleCloseUserMenu={handleCloseUserMenu}
        />
      </Box>
    </>
  );
});
