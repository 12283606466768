import React from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Theme,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@mui/styles';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { ModalProps } from '../../Stores/ModalStore';
import { FZAGPalette } from '../AppTheme';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    '&.MuiTypography-root': {
      fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
      color: FZAGPalette.text.grey,
      fontSize: '14px',
      fontWeight: 400,
    },
  },
  btn: {
    '&.MuiButton-root': {
      fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'none',
      padding: '0 10px',
      '&:last-of-type': {
        color: FZAGPalette.text.grey,
        textDecoration: 'underline',
      },
      '& .MuiSvgIcon-root': {
        fontSize: '10px',
      },
    },
  },
  content: {
    '&.MuiDialogContentText-root': {
      fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
      color: FZAGPalette.text.grey,
      fontSize: '14px',
      lineHeight: '20px',
      '& span': {
        display: 'block',
      },
      '& :last-child': {
        marginTop: '16px',
      },
    },
    '& .MuiLink-root': {
      color: FZAGPalette.link.navy,
      textDecoration: `underline ${FZAGPalette.link.navy}`,
    },
  },
}));

type ConfirmationDialogProps = ModalProps & {
  title?: string;
  content: ReactJSXElement;
  submitButtonText?: string;
  cancelButtonText?: string;
};

export const ConfirmationModal: React.FC<ConfirmationDialogProps> = observer(
  ({ title, content, submitButtonText, cancelButtonText, ...props }) => {
    const styles = useStyles();

    return (
      <Dialog
        {...props}
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && (
          <DialogTitle id="alert-dialog-title" className={styles.title}>
            {title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={styles.content}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: '16px' }}>
          <Button
            onClick={props.onSubmit}
            startIcon={<ArrowForwardIosIcon />}
            variant="contained"
            className={styles.btn}
          >
            {submitButtonText}
          </Button>
          <Button onClick={props.onClose} className={styles.btn}>
            {cancelButtonText || 'Abbrechen'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
