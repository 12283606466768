import * as React from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { DataGrid, GridSortItem } from '@mui/x-data-grid';
import { Theme, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { FZAGPalette } from '../AppTheme';
import { documentsStore } from '../../Stores/DocumentsStore';
import { SortModel } from '../../Stores/types';
import { Spinner } from '../Spinner';
import { getColumns } from './columns';
import { configService } from '../../index';

const defaultSortModel = [
  {
    field: 'filename',
    sort: 'desc',
  },
  {
    field: 'uploaded',
    sort: 'desc',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 'calc( 100% - 12px )',
    margin: '6px 6px 0 6px',
    backgroundColor: theme.palette.common.white,
  },
  grid: {
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      position: 'relative',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      height: 'max-content !important',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'max-content !important',
      color: FZAGPalette.text.grey,
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell': {
      padding: '4px 0',
      maxHeight: 'max-content !important',
      fontSize: '14px',
      fontWeight: 400,
      paddingLeft: '15px!important',
      '&:focus, &:focus-within': {
        outline: 'none',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '14px',
      color: FZAGPalette.text.grey,
      fontWeight: 400,
    },
    '& .MuiDataGrid-row .MuiDataGrid-cell': {
      '&:first-of-type': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    '& .MuiCheckbox-root .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
      fill: FZAGPalette.text.grey,
    },
    '& .Mui-checked.MuiCheckbox-root .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
      fontSize: '12px',
      color: alpha(theme.palette.common.black, 0.54),
    },
    '& .MuiTablePagination-select': {
      fontSize: '12px',
      color: FZAGPalette.text.grey,
    },
    '& .MuiDataGrid-cell--withRenderer': {
      '&>*': {
        lineHeight: 1.4,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        '& *': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
        },
      },
    },
    '& .MuiDataGrid-overlay': {
      top: '66px !important',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
      {
        width: 'auto',
        visibility: 'visible',
      },
    '& .MuiDataGrid-columnHeader .MuiIconButton-root': {
      '& .MuiSvgIcon-root': {
        width: '18px',
        height: '18px',
        opacity: 0.3,
      },
      '& .MuiDataGrid-sortIcon': {
        opacity: 0.8,
      },
    },
  },
  infoTooltip: {
    '&.MuiTooltip-tooltip': {
      minWidth: '200px',
      maxWidth: 'max-content',
      padding: '10px',
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '300px',
      },
    },
  },
  infoTitle: {
    '&.MuiTypography-root': {
      fontWeight: 500,
      fontSize: '15px',
      marginBottom: '5px',
    },
  },
  description: {
    '&.MuiTypography-root': {
      fontSize: '12px',
      lineHeight: 1,
      padding: '2px 0',
    },
  },
  infoIcon: {
    color: FZAGPalette.grey.smog,
  },
  derivations: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileExtension: {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: FZAGPalette.grey.smog,
    color: FZAGPalette.common.white,
    fontSize: '8px',
    fontWeight: 600,
    fontFamily: 'monospace',
    '&:not(:last-of-type)': {
      marginBottom: '5px',
    },
  },
}));

const CustomUnsortedIcon = () => <ArrowDownwardIcon />;

export const DataTable: React.FC = observer(() => {
  const styles = useStyles();
  const {
    documents,
    init,
    onCheckSendNotifications,
    page,
    loading,
    sortModel,
    onSetSortModel,
    totalItemsCount,
    pageSize,
    onHandlePageChange,
    onHandlePageSizeChange,
    onHandleDownloadDocument,
  } = documentsStore;
  const { searchInfo } = configService.appConfig;
  const { noResultsText, documentsPerPageText } = searchInfo;

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleCheckSendNotifications = (
    id: string | number,
    isChecked: boolean,
  ) => {
    onCheckSendNotifications(id, isChecked);
  };

  const onHandleDownload = (id: string | number) => {
    onHandleDownloadDocument(id);
  };

  const handleSortModelChange = (model: SortModel[] | any) =>
    onSetSortModel(model);

  return (
    <>
      {loading && <Spinner />}
      <div className={styles.wrapper}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: defaultSortModel as GridSortItem[],
            },
          }}
          rows={documents}
          columns={getColumns({
            onCheck: onHandleCheckSendNotifications,
            onDownload: onHandleDownload,
            styles,
          })}
          disableSelectionOnClick
          disableColumnMenu
          page={page}
          pageSize={pageSize}
          rowCount={totalItemsCount}
          rowsPerPageOptions={[5, 10, 20, 50]}
          autoHeight
          paginationMode="server"
          pagination
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          className={styles.grid}
          onPageChange={onHandlePageChange}
          onPageSizeChange={(pageSize) => onHandlePageSizeChange(pageSize)}
          localeText={{
            noRowsLabel: `${noResultsText}`,
            noResultsOverlayLabel: `${noResultsText}`,
            MuiTablePagination: {
              labelRowsPerPage: `${documentsPerPageText}`,
              labelDisplayedRows: ({ from, to, count }) =>
                `${from}-${to} von ${count}`,
            },
          }}
          components={{
            ColumnUnsortedIcon: CustomUnsortedIcon,
          }}
        />
      </div>
    </>
  );
});
