import { ThemeProvider, createTheme, alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

export const AppTheme: React.FC = (props) => {
  return (
    <ThemeProvider theme={FZAGTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};

export const FZAGPalette = {
  primary: {
    main: '#82d2f0',
  },
  text: {
    grey: alpha('#000', 0.87),
    lightGrey: alpha('#000', 0.5),
    error: '#eee',
  },
  grey: {
    dark: '#e4e4e4',
    middleLight: '#c1c1c1',
    light: '#f2f2f2',
    mountain: '#585858',
    smog: alpha('#000', 0.56),
    dust: '#aaa',
  },
  blue: {
    light: '#5B93A8',
  },
  link: {
    navy: '#0000EE',
  },
  boxShadow: {
    button:
      '0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%)',
    card: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)',
    buttonHover:
      'rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px',
    paper:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  },
  common: {
    black: '#000',
    white: '#fff',
    violet: '#3f51b5',
  },
};

let FZAGTheme = createTheme({
  palette: {
    primary: {
      main: FZAGPalette.primary.main,
    },
    text: {
      primary: FZAGPalette.common.black,
      secondary: FZAGPalette.text.grey,
      disabled: FZAGPalette.text.lightGrey,
    },
    background: {
      default: FZAGPalette.common.white,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Open Sans, Helvetica Neue, sans-serif',
  },
  shape: {
    borderRadius: 4,
  },
});

FZAGTheme = createTheme(FZAGTheme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '56px',
          backgroundColor: FZAGPalette.grey.light,
          padding: `0 ${FZAGTheme.spacing(2)}`,
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '56px',
          minHeight: '56px',
          [FZAGTheme.breakpoints.up('xs')]: {
            minHeight: '56px',
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          margin: '0 !important',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: FZAGTheme.typography.fontSize,
          fontWeight: 400,
          color: FZAGTheme.palette.text.secondary,
          lineHeight: '23px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: '38px',
          fontSize: FZAGTheme.typography.fontSize,
          color: FZAGPalette.common.white,
          textTransform: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: FZAGPalette.grey.smog,
          '&:hover': {
            background: FZAGPalette.common.white,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: FZAGPalette.link.navy,
          textDecorationColor: FZAGPalette.link.navy,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: FZAGTheme.typography.fontSize,
          color: FZAGPalette.text.grey,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: FZAGTheme.typography.fontSize,
          color: FZAGPalette.text.grey,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .MuiMenuItem-root': {
            padding: '0 10px',
            minHeight: '22px',
          },
          '& .MuiTypography-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 220,
          backgroundColor: FZAGTheme.palette.background.default,
          color: FZAGTheme.palette.text.secondary,
          fontSize: FZAGTheme.typography.fontSize,
          whiteSpace: 'pre',
          border: '1px solid #dadde9',
          filter: 'drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 10px)',
          marginTop: '0!important',
        },
      },
    },
  },
});
