import * as React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { FZAGPalette } from './AppTheme';
import { documentsStore } from '../Stores/DocumentsStore';
import { configService } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  block: {
    position: 'relative',
    flexBasis: '100%',
    height: '42px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    transition: 'all .3s ease-in-out',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexBasis: 'auto',
      order: 1,
      marginBottom: '10px',
      marginRight: 0,
    },
  },
  shortBlock: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '300px !important',
    },
  },
  searchIconWrapper: {
    height: '100%',
    position: 'absolute',
    zIndex: '10',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    '& .MuiSvgIcon-root': {
      width: '20px',
      fill: FZAGPalette.grey.mountain,
    },
  },
  input: {
    width: '100%',
    height: '100%',
    color: 'inherit',
    boxShadow:
      '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)',
    '& .MuiInputBase-input': {
      height: '100%',
      fontSize: '15px',
      padding: 0,
      paddingLeft: `${theme.spacing(6)}`,
      '&::placeholder': {
        color: FZAGPalette.text.lightGrey,
        opacity: 1,
      },
      '&::-webkit-input-placeholder': {
        color: FZAGPalette.text.lightGrey,
        opacity: 1,
      },
      '&::-moz-placeholder': {
        color: FZAGPalette.text.lightGrey,
        opacity: 1,
      },
      '&:-ms-input-placeholder': {
        color: FZAGPalette.text.lightGrey,
        opacity: 1,
      },
      '&:-moz-placeholder': {
        color: FZAGPalette.text.lightGrey,
        opacity: 1,
      },
    },
  },
}));

export const Search: React.FC<{ className?: string }> = observer((props) => {
  const styles = useStyles();
  const { searchValue, onSearch } = documentsStore;
  const { searchInfo } = configService.appConfig;
  const { searchInputText } = searchInfo;

  const [isOnFocus, setIsOnFocus] = React.useState<boolean>(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  return (
    <Box
      {...props}
      className={clsx(styles.block, !isOnFocus && styles.shortBlock)}
    >
      <Box className={styles.searchIconWrapper}>
        <SearchIcon />
      </Box>
      <InputBase
        onFocus={() => setIsOnFocus(true)}
        onBlur={() => setIsOnFocus(false)}
        className={styles.input}
        placeholder={searchInputText || 'Suchen'}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch}
        value={searchValue}
      />
    </Box>
  );
});
