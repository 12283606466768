import axios, { AxiosError } from 'axios';
import { authService } from '../../index';
import { api } from './helpers';
import { URLS } from './urls';
import { popup } from '../Popup';

export async function updateAuthInterceptor(error: AxiosError | any) {
  const { response } = error;
  const originalRequest = error.config;
  const token = authService.getAuthToken();

  if (token && response && response.status === 401) {
    try {
      await authService.renewToken();
      setAuthInterceptor(response.config);

      originalRequest.headers.Authorization =
        'Bearer ' + authService.getAuthToken();

      if (originalRequest.url !== api(URLS.users)) {
        return new Promise((resolve, reject) => {
          axios
            .request(originalRequest)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
}

export async function setAuthInterceptor(config: any) {
  const token = authService.getAuthToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
  }

  return config;
}

export function unauthorizedInterceptor(error: AxiosError) {
  const { response } = error;

  if (!authService.getAuthToken() && response && response.status === 401) {
    authService.logout();
  } else {
    return Promise.reject(error);
  }
}

export async function httpErrorHandler(error: AxiosError) {
  let errorMessage = `${error?.message.toString()}`;

  if (error.response!.status === 404) {
    errorMessage = `Error ${
      error.response!.status
    }: Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.`;
  }
  if (error.response!.status >= 500) {
    errorMessage = `Error ${
      error.response!.status
    }: Es tut uns sehr leid. Es gibt ein internes Serverproblem. Versuchen Sie es später erneut.`;
  }
  if (error.response!.status !== 401) {
    popup.notify(errorMessage);
  }

  return Promise.reject(error);
}
