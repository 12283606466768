import * as React from 'react';
import { styled } from '@mui/material/styles';
import TreeView, { TreeViewProps } from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from '@react-spring/web';
import { TransitionProps } from '@mui/material/transitions';
import { documentsStore } from '../Stores/DocumentsStore';
import { Hierarchy } from '../Stores/types';
import { Checkbox, FormControlLabel } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { FZAGPalette } from './AppTheme';
import { observer } from 'mobx-react';

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeView = styled((props: TreeViewProps) => (
  <TreeView {...props} />
))(({ theme }) => ({
  [`&>.${treeItemClasses.root}`]: {
    [`&>.${treeItemClasses.content}`]: {
      justifyContent: 'space-between',
      borderBottom: `1px solid ${FZAGPalette.grey.middleLight}`,
      '&>.MuiTreeItem-label': {
        '& .MuiTypography-root': {
          fontWeight: 600,
        },
      },
      [`&>.${treeItemClasses.iconContainer}`]: {
        order: 0,
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
          color: FZAGPalette.common.violet,
        },
      },
    },
  },
}));

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
  },
  [`& .${treeItemClasses.content}`]: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      color: FZAGPalette.grey.mountain,
    },
  },
  [`& .${treeItemClasses.label}`]: {
    height: '28px',
    lineHeight: 1,
    alignItems: 'flex-start',
    '& .MuiTypography-root': {
      fontSize: '12px',
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
      color: FZAGPalette.grey.dust,
    },
    '& *': {
      height: '100%',
    },
  },
}));

export const ChaptersTree = observer(() => {
  const { hierarchies, filter, onFilter } = documentsStore;

  const renderTree = (nodes: Hierarchy) => (
    <StyledTreeItem
      key={nodes.value}
      nodeId={nodes.value}
      label={
        <FormControlLabel
          control={
            <Checkbox
              checked={filter.includes(nodes.value)}
              onChange={(event) => onFilter(event.target.checked, nodes)}
              onClick={(e) => e.stopPropagation()}
            />
          }
          label={<>{nodes.label}</>}
          key={nodes.value}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  return (
    <StyledTreeView
      aria-label="customized"
      defaultExpanded={['1']}
      defaultCollapseIcon={<RemoveIcon />}
      defaultExpandIcon={<AddIcon />}
      multiSelect={true}
      sx={{ flexGrow: 1, overflowY: 'auto' }}
    >
      {!!hierarchies?.length &&
        hierarchies?.map((hierarchy) => renderTree(hierarchy))}
    </StyledTreeView>
  );
});
