import * as React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Theme } from '@mui/material/styles';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { FZAGPalette } from './AppTheme';
import { Search } from './Search';
import { documentsStore } from '../Stores/DocumentsStore';
import { configService, modalStore } from '../index';
import { Clear } from '@mui/icons-material';
import { ChaptersTreeModal } from './Modals/ChaptersTreeModal';

const useStyles = makeStyles((theme: Theme) => ({
  searchBar: {
    minHeight: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: FZAGPalette.blue.light,
    padding: '0 16px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  title: {
    flex: 1,
    padding: '10px 0',
    '&.MuiTypography-root': {
      fontSize: '20px',
      fontWeight: 600,
      color: theme.palette.common.white,
    },
  },
  controls: {
    display: 'flex',
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '10px 0',
    '& .MuiInputBase-root .MuiInputBase-input': {
      fontSize: '14px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '75%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  treeButton: {
    '&.MuiButton-root': {
      minWidth: '183px',
      height: '42px',
      backgroundColor: FZAGPalette.primary.main,
      boxShadow: FZAGPalette.boxShadow.button,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: FZAGPalette.blue.light,
        boxShadow: FZAGPalette.boxShadow.buttonHover,
      },
    },
    [theme.breakpoints.down('md')]: {
      order: 2,
    },
  },
  filterIconActive: {
    color: FZAGPalette.common.white,
  },
  filterIcon: {
    color: FZAGPalette.grey.light,
    opacity: 0.3,
  },
  resetButton: {
    width: '32px',
    height: '32px',
    color: theme.palette.text.primary,
    boxShadow: FZAGPalette.boxShadow.button,
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
      fill: FZAGPalette.text.grey,
    },
    '&.MuiButtonBase-root': {
      background: theme.palette.common.white,
      marginLeft: '12px',
      '&:hover': {
        background: FZAGPalette.grey.light,
      },
      [theme.breakpoints.down('md')]: {
        margin: '-41px 0 10px',
      },
    },
  },
}));

export const SearchBar: React.FC = observer(() => {
  const styles = useStyles();
  const { totalItemsCount, resetFilter, isResetButtonExist } = documentsStore;
  const { dialogButtonText } = configService.appConfig.filterInfo;
  const { searchInfo } = configService.appConfig;
  const { resultsCountText } = searchInfo;

  return (
    <>
      <Box className={styles.searchBar}>
        <Box>
          <Typography noWrap component="div" className={styles.title}>
            {`${totalItemsCount} ${resultsCountText}`}
          </Typography>
        </Box>
        <Box className={clsx(styles.controls)}>
          <Search />
          <Button
            aria-label="Kapitel Auswahl"
            className={styles.treeButton}
            endIcon={<AddIcon />}
            startIcon={
              <FilterAltIcon
                className={
                  !!documentsStore.filter.length
                    ? styles.filterIconActive
                    : styles.filterIcon
                }
                onClick={documentsStore.resetFilter}
              />
            }
            onClick={async () => await modalStore.showModal(ChaptersTreeModal)}
          >
            {dialogButtonText}
          </Button>
          {isResetButtonExist && (
            <IconButton
              aria-label="reset"
              color="inherit"
              className={styles.resetButton}
              onClick={resetFilter}
            >
              <Clear />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
});
