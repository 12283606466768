import * as React from 'react';
import { observer } from 'mobx-react';

import { authService, configService } from '../../index';
import { ModalProps } from '../../Stores/ModalStore';
import { ConfirmationModal } from './ConfirmationModal';

export const DeactivationWarningModal: React.FC<ModalProps> = observer(
  ({ ...props }) => {
    const { onRevokePrivacyPolicy } = authService;
    const { deactivateInfo } = configService.appConfig;
    const { notificationText, deactivateButtonText, cancelButtonText } =
      deactivateInfo;

    const handleSubmit = () => {
      if (props.onSubmit) {
        props.onSubmit();
      }
      onRevokePrivacyPolicy();
    };

    const renderContent = () => <>{notificationText}</>;

    return (
      <ConfirmationModal
        {...props}
        content={renderContent()}
        onSubmit={handleSubmit}
        submitButtonText={deactivateButtonText}
        cancelButtonText={cancelButtonText}
      />
    );
  },
);
