export const DEFAULT_THROTTLE_MS = 300;
export const DEFAULT_DESKTOP_PAGE_SIZE = 20;
export const DEFAULT_MOBILE_PAGE_SIZE = 5;

export const DEFAULT_PAGE = 0;

export const MOBILE_DEVICES =
  'Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini';

export const FILE_URLS = {
  privacyPolicy:
    'https://www.flughafen-zuerich.ch/-/jssmedia/airport/portal/dokumente/business/airlines-and-handling/flight-operations/bodenabfertigung/Datenschutz-Flugplatzhandbuch-Flughafen-Zuerich.pdf',
};
